import React, { useState, useRef } from "react";
import { IMaskInput } from "react-imask";
import Continue from "assets/svgs/continue.svg";
import { CURRENCIES } from "components/auto_pay/constants";
import CurrencyTag from "components/auto_pay/CurrencyTag";
import { createInvoice as createInvoiceApi } from "util/api_util";
import {
  convertCurrency,
  formatCurrency,
  getCurrencySymbol,
} from "util/format_helpers";

function PayNow({
  autoPay,
  createInvoice = createInvoiceApi,
  designatedCurrency,
  onCreateInvoiceResponse,
}) {
  const MIN_AMOUNT = "20.0";
  let initialAmount = autoPay.enabled ? autoPay.payAmount : MIN_AMOUNT;
  // we want the largest minimum
  if (autoPay.enabled && autoPay.payAmount < MIN_AMOUNT) {
    initialAmount = MIN_AMOUNT;
  }
  const [amount, setAmount] = useState(initialAmount);
  const [currency, setCurrency] = useState(designatedCurrency || "USD");
  const formStates = ["idle", "submitting", "error"];
  const [formState, setFormState] = useState(formStates[0]);
  const [error, setError] = useState();
  const exchangeRates = {
    ...autoPay.exchangeRates,
    KSH: autoPay.exchangeRates.KES,
  };

  const currencySymbol = getCurrencySymbol(currency);
  const ref = useRef(null);
  const inputRef = useRef(null);

  const onChange = (changedAmount) => {
    if (parseFloat(changedAmount)) {
      setAmount(parseFloat(changedAmount));
    }
  };

  const handleCurrencyChange = (changedCurrency) => {
    if (designatedCurrency === "USD") {
      const conversion = {
        state: {
          exchangeRates,
          currency: changedCurrency,
        },
      };
      const convertedAmount = convertCurrency(
        conversion,
        parseFloat(initialAmount),
      );
      setAmount(convertedAmount);
    }
    setCurrency(changedCurrency);
  };

  const makePayment = async (event) => {
    event.preventDefault();
    setFormState("submitting");

    const conversion = {
      state: {
        exchangeRates,
        currency,
      },
    };

    try {
      const paymentAmount = parseFloat(amount);
      const originalMinimumAmount = initialAmount;

      if (currency === designatedCurrency) {
        if (paymentAmount < parseFloat(originalMinimumAmount)) {
          const errorMessage = `${formatCurrency(
            originalMinimumAmount,
            currency,
          )} is the minimum funding amount.`;
          throw new Error(errorMessage);
        }
      } else if (currency === "USD") {
        if (paymentAmount < MIN_AMOUNT) {
          const errorMessage = `${formatCurrency(
            MIN_AMOUNT,
            currency,
          )} is the minimum funding amount.`;
          throw new Error(errorMessage);
        }
      } else {
        const conversionState = { ...conversion };
        conversionState.state.currency = currency;
        const minimumAmount = convertCurrency(
          conversionState,
          parseFloat(MIN_AMOUNT),
        );
        if (paymentAmount < minimumAmount) {
          const errorMessage = `${formatCurrency(
            minimumAmount,
            currency,
          )} is the minimum funding amount.`;
          throw new Error(errorMessage);
        }
      }

      const payload = {
        currency,
        partner_id: localStorage.adminPartnerId,
        amount,
      };

      const response = await createInvoice(payload);
      if (response?.checkout_url) {
        window.open(response.checkout_url, "_self");
      } else if (response?.dlocal_sf_key) {
        onCreateInvoiceResponse({ ...response, currency, amount });
      } else if (response?.error) {
        setFormState("error");
        setError(response.error);
      }
    } catch (error) {
      setFormState("error");
      setError(error.message);
    }
  };

  const currencies = ["USD", "KES", "NGN", "ZAR"];

  return (
    <div className="stack">
      <h1 className="font-heading">Top up your wallet to get started</h1>
      <form onSubmit={makePayment} className="stack auto-pay">
        <div className="input-group auto-pay">
          <label htmlFor="currency">Select Currency</label>
          <div className="currency-tags-container">
            {currencies?.map((enabledCurrency) => (
              <CurrencyTag
                key={enabledCurrency}
                selectedValue={enabledCurrency === currency}
                id={enabledCurrency}
                value={enabledCurrency}
                title={`${enabledCurrency} - ${CURRENCIES[enabledCurrency].name}`}
                onSelect={() => handleCurrencyChange(enabledCurrency)}
              />
            ))}
          </div>
        </div>
        <div className="input-group" style={{ maxInlineSize: "15rem" }}>
          <label htmlFor="amount" id="amount">
            Amount
          </label>
          <IMaskInput
            aria-labelledby="amount"
            name="amount"
            mask={`${currencySymbol} d`}
            radix="."
            data-testid="amount"
            className="text-input"
            blocks={{
              d: {
                mask: Number,
                scale: 2,
                signed: true,
                thousandsSeparator: ",",
                padFractionalZeros: true,
                normalizeZeros: false,
                radix: ".",
              },
            }}
            value={amount.toString()}
            unmask
            ref={ref}
            inputRef={inputRef}
            onAccept={onChange}
          />
        </div>

        {formState === "error" && <p className="color-fail">{error}</p>}

        <div className="input-group" style={{ maxInlineSize: "15rem" }}>
          <p style={{ textAlign: "right" }}>
            <button
              type="submit"
              disabled={formState === "submitting"}
              data-variant="primary"
            >
              <img src={Continue} alt="" />
              <span>Proceed to Payment</span>
            </button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default PayNow;
